import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

// import 'swiper/swiper.min.css';
import 'swiper/css';
import 'swiper/css/pagination';


// import './styles.css';
// import required modules
import {Autoplay, Pagination, EffectFade }from 'swiper/modules';

export default function App() {
  return (
    <>
     <Swiper
         effect="fade" 
         autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, EffectFade]}
        className="mySwiper"
      >
                        <SwiperSlide>
                            <div className="banner banner1" >
                                <div className="container">
                                <div className="banner-content  text-center pt-md-5 pt-0">
                                    <h1 className="banner-heading">Seeking a Complete Digital Solution? </h1>
                                    <h2 className="banner-heading">Your Search Ends Here..! </h2>
                                    <p>
                                        Partner with us to achieved remarkable results with our proven expertise
                                    </p>
                                    <div>
                                        <Link to="/contact-us" className="btn btn-2 mt-4">Get Started</Link>
                                        <Link to="/services" className="btn btn-1 mx-3  mt-4">More</Link>
                                    </div>
                                </div>
                                </div>
                            </div> 
                        </SwiperSlide>
                    <SwiperSlide>
                            <div className="banner banner2" style={{}}>
                                <div className="container ">
                                    <div className="banner-content  text-center">
                                        <h4 className='text-white'>
                                            Our Stunning Graphic Design Solutions   
                                        </h4>
                                        <h1 className="banner-heading">Elevate Your Brand With</h1>
                                        <div className='text-center'>
                                            <Link to="/services" className="text-white fs-5">Learn More <i className="fa-solid fa-angles-right"></i></Link>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="banner banner3" style={{}}>
                            <div className="container">
                            <div className="banner-content  text-center">
                                <h4 className='text-white'>
                                    Expert Website Development
                                </h4>
                                <h1 className="banner-heading">Build Your Digital Presence with</h1>
                                <div>
                                <Link to="/services" className="text-white fs-5">Learn More <i className="fa-solid fa-angles-right"></i></Link>

                                </div>
                            </div>
                            </div>
                        </div> 
                    </SwiperSlide>
                    <SwiperSlide>
                            <div className="banner banner4" style={{}}>
                            <div className="container">
                            <div className="banner-content  text-center">
                                <h4 className='text-white'>
                                    Our Proven SEO Strategies
                                </h4>
                                <h1 className="banner-heading">Optimize Your Online Presence with</h1>
                                <div>
                                <Link to="/services" className="text-white fs-5">Learn More <i className="fa-solid fa-angles-right"></i></Link>

                                </div>
                            </div>
                            </div>
                        </div> 
                    </SwiperSlide>
                   
        
      </Swiper>
    </>
  );
}

