import React from 'react'
import { Link } from 'react-router-dom';




export default function Services(props) {
  return (
    <>
        <section className='padding service-banner'>
          <div className="container text-center mt-5 pt-4">
                    <h1 className='text-white'>Services</h1>
                    <h5 className='text-white mt-1 fw-bold'>Digital Marketing Solutions for Small Business</h5>
                    <h5 className='text-white mt-1 fw-bold'>Branding / Graphic Design / Packaging Design / Logo Design / Company branding </h5>
                    <h5 className='text-white mt-1 fw-bold'> SEO / Web Development / Digital Marketing / Stationary Design</h5>
                    <div className="mt-4">
                      <Link to="/contact-us" className='btn btn-2'>
                          Get Your Free Digital Audit
                      </Link>
                    </div>
          </div>
        </section>

        <section className="padding">
          <div className="container">
             
              <div className="row my-5">
                <div className="col-md-6">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-01.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Corporate Identity & Branding Solutions</h2>
                    <p>Unify Your Identity and Stationery Design for a Lasting Impression. Elevate your brand presence with our Corporate Identity & Branding service. Seamlessly blend identity and stationery design for a cohesive brand experience.
                    </p>

                    <ul>
                      <li>Craft memorable logos that leave a lasting impression.</li>
                      <li>Design stunning business cards, letterheads, and more.</li>
                      <li>Develop a comprehensive branding strategy tailored to your unique needs.</li>
                      <li>Stand out in the competitive landscape with creativity and strategic insight.</li>
                    </ul>

                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Custom Signs and Graphics Service</h2>
                    <p>Elevate Your Space with Custom Signs and Graphics Tailored to Your Brand. From eye-catching storefront signage to captivating interior graphics, we transform your space into a visual masterpiece that reflects your brand identity and captivates your audience. 
                    </p>

                    <ul>
                      <li>Eye-catching storefront signage that draws attention.</li>
                      <li>Captivating interior graphics for a cohesive brand experience.</li>
                      <li>Quality craftsmanship ensuring lasting impressions.</li>
                      <li>Meticulous attention to detail reflects your brand identity.</li>
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>

                <div className="col-md-6 order-md-2 order-1 ">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-02.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

              </div>


              <div className="row my-5">
                <div className="col-md-6">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-03.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'> Prime Labels and Packaging Solutions</h2>
                    <p>At the forefront of quality and innovation, our Prime Labels and Packaging services are designed to enhance the visual appeal and marketability of your products. From eye-catching labels that command attention to meticulously crafted packaging solutions that exude professionalism, we tailor every aspect to reflect your brand's identity and values.
                    </p>

                    <ul>
                      <li>Customized labels and packaging reflecting your brand identity.</li>
                      <li>High-quality materials and printing for a professional finish.</li>
                      <li>Attention to detail ensuring every product shines.</li>
                      <li>Tailored solutions to meet your unique packaging needs.</li>
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Premium Commercial Printing Services</h2>
                    <p>Our commercial Printing solutions are designed to elevate your brand's image and captivate your audience. From stunning business cards that make a lasting impression to vibrant brochures that tell your story with clarity and precision, we deliver excellence in every print.
                    </p>

                    <ul>
                      <li>High-quality prints that command attention.</li>
                      <li>Precision and attention to detail in every project.</li>
                      <li>Customized solutions to suit your specific needs.</li>
                      <li>Elevate your brand's image with professional print materials</li>
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>

                <div className="col-md-6 order-md-2 order-1 ">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-04.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

              </div>


              <div className="row my-5">
                <div className="col-md-6">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-05.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Promotional Marketing Solutions</h2>
                    <p>Fuel Your Brand's Growth with Our Promotional Marketing solutions are strategically crafted to ignite your brand's reach and drive meaningful engagement. From captivating campaigns that grab attention to targeted promotions that resonate with your audience, we deliver results that exceed expectations.

                    </p>

                    <ul>
                      <li>Strategic campaigns that grab attention and drive engagement.</li>
                      <li>Targeted promotions that resonate with your audience.</li>
                      <li>Customized solutions tailored to your brand's unique needs.</li>
                      <li>Ignite your brand's reach and drive meaningful results with our expert promotional marketing solutions.</li>
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Expert Website Design Services.</h2>
                    <p>Our Website Design services are meticulously tailored to elevate your brand and achieve your goals. From captivating layouts that capture attention to seamless user experiences that drive engagement, we deliver excellence in every aspect of website design. 
                    </p>

                    <ul>
                      <li>Captivating layouts that capture the attention.</li>
                      <li>Seamless user experiences that drive engagement.</li>
                      <li>Customized solutions tailored to your brand's goals.</li>
                      <li>Elevate your brand's online presence with our expert website design services.</li>
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>

                <div className="col-md-6 order-md-2 order-1 ">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-06.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

              </div>


              <div className="row my-5">
                <div className="col-md-6">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-07.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Expert Website Development.</h2>
                    <p>Our Website Development services are designed to revolutionize your online presence and drive tangible results. From basic websites that establish your digital footprint to informative websites that educate and engage your audience, and eCommerce stores that drive sales and revenue, we deliver excellence in every project.

                    </p>

                    <ul>
                      <li>Basic Website: Establish your digital footprint with a simple yet effective online presence.</li>
                      <li>Informative Website: Educate and engage your audience with informative content and seamless navigation.</li>
                      <li>eCommerce Store Website: Drive sales and revenue with a fully functional eCommerce platform tailored to your business needs.</li>

                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>
              </div>

              <div className="row my-5 py-5 ">
               

                <div className="col-md-6 my-auto order-md-1 order-2 mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Harness the Power of SEO</h2>
                    <p>Our SEO services are designed to propel your website to the top of search results and drive meaningful traffic to your online presence. From comprehensive keyword research that identifies the most lucrative opportunities to on-page optimization that maximizes your website's visibility and off-page strategies that build authoritative backlinks, we deliver results that exceed expectations.
                    </p>

                    <ul>
                      <li>Keyword Research: Identify the most relevant and profitable keywords for your business.</li>
                      <li>On-Page Optimization: Optimize your website's structure, content, and meta tags to maximize search visibility.</li>
                      <li>Off-Page Strategies: Build authoritative backlinks and establish your website's credibility across the web.</li>
                     
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                </div>

                <div className="col-md-6 order-md-2 order-1 ">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-08.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

              </div>

              <div className="row my-5">
                <div className="col-md-6">
                  <div className="service-img-contain">
                      <img src="static/images/services/services-09.webp"  alt="" loading="lazy" className='img-fluid'></img>
                  </div>
                </div>

                <div className="col-md-6 my-auto mt-md-auto mt-4">
                  <h2 className='fw-bold text-blue lined2 mb-4'>Digital Marketing Services</h2>
                    <p>Our Digital Marketing solutions are designed to accelerate your business and reach new heights. From targeted Facebook Meta ads that capture attention to dynamic social media campaigns that engage your audience and platform-specific ads that drive results, we deliver tailored solutions that exceed expectations.
                    </p>

                    <ul>
                      <li>Facebook Meta Ads: Captivate your audience with targeted and visually stunning ads on the Facebook Meta platform.</li>
                      <li>Social Media Campaigns: Engage your audience with dynamic and impactful campaigns across various social media channels.</li>
                      <li>Social Media Platform Ads: Drive results with platform-specific ads tailored to your target audience and business goals.</li>
                      
                    </ul>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Book An Appointment</Link>
                    </div>
                      
                </div>
              </div>



          </div>
        </section>


        
    </>
  )
}
