import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';



// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';


export default function Testimonials() {
  return (
    <>
    <section className='bg-light padding'>
      <div className="container">
          <h1 className='fw-bold text-center'>Success Stories From Clients</h1>
    
      <Swiper
        
        grabCursor={true}
        // centeredSlides={true}
        slidesPerView={3}
        spaceBetween={30}

        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        loop={true}
        pagination={{
          clickable:true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper py-5"
        breakpoints={{
          // when window width is <= 768px
          1200:{
            slidesPerView: 3,
            spaceBetween:30,
          },
          1100:{
            slidesPerView: 3,
            spaceBetween:30,
          },
          992:{
            slidesPerView: 3,
            spaceBetween: 30,

          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,

          },
          // when window width is <= 576px
          576: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          }
        }}
      >
        <SwiperSlide>
            <div className="testimonial mx-auto text-center">
                  <div className='my-2'>
                    <img src="static/images/test1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='50px'></img>  
                  </div>
                  <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                        " className='my-2'>
                          <div className='text-center'>
                            <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid text-center' width="130px"></img>
                          </div>
                    </a>
               
                  <p>
                    I have had nothing but an excellent experience every time i have used these guys. Prompt service and extremely creative!!</p>
                  <h6>~Rob Minilaloff~</h6>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial mx-auto text-center">
                  <div className='my-2'>
                    <img src="static/images/about/avatar2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='50px'></img>  
                  </div>
                  <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                        " className='my-2'>
                          <div className='text-center'>
                            <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid text-center' width="130px"></img>
                          </div>
                    </a>
                  
                  <p>
                    Prompt and extremely professional! Absolutely loved the design work!!</p>
                  <h6>~Klmberly~</h6>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial mx-auto text-center">
                  <div className='my-2'>
                    <img src="static/images/test2.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='50px'></img>  
                  </div>
                  <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                        " className='my-2'>
                          <div className='text-center'>
                            <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid text-center' width="130px"></img>
                          </div>
                    </a>
                 
                  <p>
                    Fast and friendly service, easy to communicate with and delivered an amazing logo and website for our new company that exceeded our expectations!</p>
                  <h6>~Carly Cline~</h6>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial mx-auto text-center">
                   <div className='my-2'>
                    <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='50px'></img> 
                    </div>
                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                        " className='my-2'>
                          <div className='text-center'>
                            <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid text-center' width="130px"></img>
                          </div>
                    </a>
                  <p>
                    They came up with a great design right away and gave me a few different opitions through out the process. I will use them again.</p>
                  <h6>~Jason Brace~</h6>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial mx-auto text-center">
                   <div className='my-2'>
                    <img src="static/images/about/avatar1.webp"  alt="" loading="lazy" className='img-fluid rounded-circle ' width='50px'></img> 
                    </div>
                    <a  href="https://www.trustpilot.com/review/mediaproductionart.com
                        " className='my-2'>
                          <div className='text-center'>
                            <img src="static/images/stars-5.webp"  alt="" loading="lazy" className='img-fluid text-center' width="130px"></img>
                          </div>
                    </a>
                  
                  <p>
                    We thank you for the excellent work in the past. Creative, progressive, modern and does a great job! Again and again!</p>
                  <h6>~Nevzat~</h6>
            </div>
        </SwiperSlide>
      </Swiper>

      </div>
      

    </section>

    </>
  )
}
