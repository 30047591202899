import React from 'react'

export default function Wpicon() {
  return (
    <>
    <section>
    <div className="container">

      <a href='https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0' rel='noreferrer' target='_blank' className='wp-icon text-center'>
            <img src="static/images/1.webp" alt=""  loading="lazy" className="img-fluid"></img>
      </a>

    </div>
    </section>

      
    </>
  )
}

