import React, {useState} from 'react'
import { Link } from 'react-router-dom';



export default function Packages() {
  
    const [activeLink, setActiveLink] = useState(0); // State to track active link index

    const handleClick = (index) => {
      setActiveLink(index); // Update active link index
    };

    const change1 = ()=>{  
        handleClick(0);
    }
    const change2 = ()=>{            
        handleClick(1);
    }
    const change3 = ()=>{
        handleClick(2);
    }
    

  return (
    <>
      {/* popup code start ...........................  */}
      {/* <div className="pop-up padding">
        <div className="container">
        <div className='text-end'>
          <p className='close-popup' onClick={popupOff}>
            <i className="fa-solid fa-xmark fs-4 text-white"></i>
          </p>
        </div>
            <div className='form bg-white mx-auto'>
                    <div>
                        <h3 className='fw-bold'>We’ll Be Glad To Assist You!</h3>
                       
                    </div>
                        <form ref={form} action="" className='' onSubmit={sendEmail}>
                        <div className='mt-3'>
                                <input type="text" required className='input w-100' placeholder="Name" name="from_name" />
                            </div>
                            <div className='mt-3'>
                                <input type="tel"  className='input w-100' placeholder="Phone Number" name="from_phone"/>
                            </div>
                            <div className='mt-3'>
                                <input type="email" required className='input w-100' placeholder="Email Address" name="from_email" />
                            </div>
                            
                            <div className='mt-3'>
                                <textarea type="text" required className='input w-100 ' rows="5" placeholder="Your Message" name="message" />
                            </div>
                            <div className='text-center'>
                                <button className='btn btn-1 mt-3 ' type="submit">Send Message</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
              </div> */}

      {/* popup code ends..............................  */}
     
      <section className="padding package-banner">
        <div className="container">
            <h1 className='text-center text-white'>
                MPA Packages
            </h1>
            <h6 className='text-center px-lg-5 px-0 mx-lg-5 mx-0 mt-3 text-white'>
            "Elevate your digital presence with our tailored packages. From web development to graphic design, we offer comprehensive solutions for your business needs."
            </h6>
            <div className="mt-4 text-center">
                <Link to="/contact-us" className='btn btn-2'>
                    Get Your Free Digital Audit
                </Link>
            </div>
        </div>
      </section>

      <section className="padding bg-blue">
        <div className="container">
            <h1 className="my-5 text-white text-center">Our Packages</h1>
            <ul className='package-ul my-5 text-center row '>
                  <li className='col-md-4'><button className={`package-link ${activeLink === 0 ? 'active-package' : ''}`} onClick={change1}>All</button></li>
                  <li className='col-md-4 mt-md-0 mt-5'><button className={`package-link ${activeLink === 1 ? 'active-package' : ''}`} onClick={change2} >Graphic Design</button></li>
                  <li className='col-md-4 mt-md-0 mt-5'><button className={`package-link ${activeLink === 2 ? 'active-package' : ''}`} onClick={change3}>Website</button></li>
                </ul>

            {activeLink === 0 &&
                <>
                <div className="row ">
                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4 ">
                               <h4 className="fw-bold text-blue">Media Bronze</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                               
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>Get 6 Custom Logo design concepts</li>
                           <li>1 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
              
                         <div className="mt-4 text-xl-start text-center">
                           <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                                         
                           <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                         </div>
                       </div>
                    </div>

                    <div className="col-lg-4 mt-lg-0 mt-4 mt-md-0 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Silver</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">42%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>Get 10 Logo design concepts</li>
                           <li>3 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups Revision (Up to 8)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                         </div>
                       </div>
                     </div>

                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Gold</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>15 Logo design concepts</li>
                           <li>1 Landing Page Design Concept (Up to 2 Revisions)</li>
                           <li>4 Dedicated Designer</li>
                           <li>Rounds Of Revision (Up to 7)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Envelope Design (JPG Format)</li>
                           <li>Icon Design (ICO Format)</li>
                           <li>Social Header Design (JPG, PNG & PSD Format)</li>
                           <li>Email Signature Design (JPG, PNG & PSD Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                         </div>
                        
                       </div>
                     </div>

    
                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 1</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>5 Pages Template Based Website</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>5 Logo Design Concepts</li>
                          <li>4 Dedicated Designers</li>
                          <li>Rounds of Revision (Up to 3)</li>
                          <li>Raster Files Format (JPG, PNG & PSD) </li>
                          <li>Vector Files Format (AI, EPS & PDF) </li>
                          <li>Business Card Design (JPG Format)</li>
                          <li>Satisfaction Guaranteed</li>
                          <li>Unique Design Guaranteed</li>
                          <li>Money Back Gaurantee</li>
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                          <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>
                   

                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 2</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>5 Unique Design Pages</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 3 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 3 Months Web Hosting</li>
                          <li>FREE 1GB Web Hosting Space</li>
                          <li>3 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                          <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>


                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Pro - 1</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>8 Pages Template Based Website</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 5 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 6 Months Web Hosting</li>
                          <li>FREE 3GB Web Hosting Space</li>
                          <li>6 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                          <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>


                    <div className="col-12 mt-4">
                         <div className="combo pb-4 bg-white">
                           <div className="row">
                               <div className="col-6 col-md-10 text-blue mt-4">
                                 <h4 className="">All In One</h4>
                                 <h1 className="fw-bold">Combo Package</h1>
                           
                                 
                                 <p className="fw-bold">Ultimate Value for Start-ups and Brand(s)</p>
                               
                               </div>
     
     
                                 <div className="col-md-2 col-6">
                                   <div className="tag text-center">
                                     <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                     <h5 className="fw-bold text-white">OFF</h5>
                                   </div>
                                 </div>
                         </div>
     
                         <div className="row">
                           <div className="col-md-8 text-dark">
                             <div className="row mt-4">
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-xl-start text-center">
                                     Logo Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Website Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Value Added Services
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Stationary Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Buisness Card, Letterhead, Envelope</li>
                                     <li>Ms Word Letterhead</li>
                                   </ul>
                                 </div>
     
     
                             </div>
                           </div>
                           <div className="col-md-4 text-blue text-center my-auto">
                               <h4>Combo Package</h4>
                               <div className="my-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                               <p>LIMITED TIME OFFER</p>
                               <small>Want To Discuss</small>
                               <div className="mt-4">
                                 <Link to="/contact-us" className="btn btn-1">Order Now</Link>
                               </div>
                           </div>
                         </div>
                         </div>
                         </div>







                    </div>

                    </>
                    }


              {activeLink === 1 &&
                <>
                <div className="row ">
                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Bronze</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>Get 6 Custom Logo design concepts</li>
                           <li>1 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                           
                         </div>
                       </div>
                    </div>

                    <div className="col-lg-4 mt-lg-0 mt-4 mt-md-0 ">
                       
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Silver</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">42%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>Get 10 Logo design concepts</li>
                           <li>3 Dedicated Brand Designer</li>
                           <li>Get Rounds of Mockups Revision (Up to 8)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
     
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                            <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                         </div>
                       </div>
                     </div>

                     <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                       <div className="package mx-auto pb-4 mt-4 bg-white">
                         <div className="row">
                             <div className="col-8 text-blue mt-4">
                               <h4 className="fw-bold text-blue">Media Gold</h4>
                               <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                             </div>
                               <div className="col-4">
                                 <div className="tag text-center">
                                   <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                   <h5 className="fw-bold text-white">OFF</h5>
                                 </div>
                               </div>
                         </div>
                           
                         <ul className="text-dark mt-4">
                           <li>15 Logo design concepts</li>
                           <li>1 Landing Page Design Concept (Up to 2 Revisions)</li>
                           <li>4 Dedicated Designer</li>
                           <li>Rounds Of Revision (Up to 7)</li>
                           <li>Raster Files Format (JPG, PNG & PSD)</li>
                           <li>Vector files Format (AI, ESP, PDF) </li>
                           <li>Business Card Design (JPG Format)</li>
                           <li>Letterhead Design (JPG Format)</li>
                           <li>Envelope Design (JPG Format)</li>
                           <li>Icon Design (ICO Format)</li>
                           <li>Social Header Design (JPG, PNG & PSD Format)</li>
                           <li>Email Signature Design (JPG, PNG & PSD Format)</li>
                           <li>Satisfaction Guaranteed</li>
                           <li>Unique Design Guaranteed</li>
                           <li>Money Back Gaurantee</li>
                         </ul>
                         <div className="mt-4 text-xl-start text-center">
                       <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                            <Link to="/graphic-design" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                         </div>
                        
                       </div>
                     </div>


                     <div className="col-12 mt-4">
                         <div className="combo pb-4 bg-white">
                           <div className="row">
                               <div className="col-6 col-md-10 text-blue mt-4">
                                 <h4 className="">All In One</h4>
                                 <h1 className="fw-bold">Combo Package</h1>
                           
                                 
                                 <p className="fw-bold">Ultimate Value for Start-ups and Brand(s)</p>
                               
                               </div>
     
     
                                 <div className="col-md-2 col-6">
                                   <div className="tag text-center">
                                     <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                     <h5 className="fw-bold text-white">OFF</h5>
                                   </div>
                                 </div>
                         </div>
     
                         <div className="row">
                           <div className="col-md-8 text-dark">
                             <div className="row mt-4">
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-xl-start text-center">
                                     Logo Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Website Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Value Added Services
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Stationary Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Buisness Card, Letterhead, Envelope</li>
                                     <li>Ms Word Letterhead</li>
                                   </ul>
                                 </div>
     
     
                             </div>
                           </div>
                           <div className="col-md-4 text-blue text-center my-auto">
                               <h4>Combo Package</h4>
                               <div className="my-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                               <p>LIMITED TIME OFFER</p>
                               <small>Want To Discuss</small>
                               <div className="mt-4">
                                 <Link to="/contact-us" className="btn btn-1">Order Now</Link>
                               </div>
                           </div>
                         </div>
                         </div>
                         </div>



                     </div>
                     </>
                     }



              {activeLink === 2 &&
                <>
                <div className="row ">
               
                <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 1</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>5 Pages Template Based Website</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>5 Logo Design Concepts</li>
                          <li>4 Dedicated Designers</li>
                          <li>Rounds of Revision (Up to 3)</li>
                          <li>Raster Files Format (JPG, PNG & PSD) </li>
                          <li>Vector Files Format (AI, EPS & PDF) </li>
                          <li>Business Card Design (JPG Format)</li>
                          <li>Satisfaction Guaranteed</li>
                          <li>Unique Design Guaranteed</li>
                          <li>Money Back Gaurantee</li>
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>
                   

                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Basic - 2</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>5 Unique Design Pages</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 3 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 3 Months Web Hosting</li>
                          <li>FREE 1GB Web Hosting Space</li>
                          <li>3 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>


                    <div className="col-lg-4 mt-lg-0 mt-4 ">
                      
                      <div className="package mx-auto pb-4 mt-4 bg-white">
                        <div className="row">
                            <div className="col-8 text-blue mt-4">
                              <h4 className="fw-bold text-blue">Media Pro - 1</h4>
                              <div className="mt-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                            </div>
                              <div className="col-4">
                                <div className="tag text-center">
                                  <h5 className="fw-bold text-white pb-0 my-1">30%</h5>
                                  <h5 className="fw-bold text-white">OFF</h5>
                                </div>
                              </div>
                        </div>
                          
                        <ul className="text-dark mt-4">
                          <li>8 Pages Template Based Website</li>
                          <li>1 Basic Contact / Inquiry Form</li>
                          <li>Responsive (Mobile / Tablet)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free 5 Stock Photos</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 6 Months Web Hosting</li>
                          <li>FREE 3GB Web Hosting Space</li>
                          <li>6 Months of Free Domain Registration</li>
                          
                        </ul>
                        <div className="mt-4 text-xl-start text-center">
                      <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" className="btn btn-1 m-2 px-3">Order Now</Link>
                           <Link to="/web-development" className="btn btn-1 m-2 px-3">View Portfolio</Link>
                        </div>
                       
                      </div>
                    </div>



                     <div className="col-12 mt-4">
                         <div className="combo pb-4 bg-white">
                           <div className="row">
                               <div className="col-6 col-md-10 text-blue mt-4">
                                 <h4 className="ext-white">All In One</h4>
                                 <h1 className="fw-bold">Combo Package</h1>
                           
                                 
                                 <p className="fw-bold">Ultimate Value for Start-ups and Brand(s)</p>
                               
                               </div>
     
     
                                 <div className="col-md-2 col-6">
                                   <div className="tag text-center">
                                     <h5 className="fw-bold text-white pb-0 my-1">50%</h5>
                                     <h5 className="fw-bold text-white">OFF</h5>
                                   </div>
                                 </div>
                         </div>
     
                         <div className="row">
                           <div className="col-md-8 text-dark">
                             <div className="row mt-4">
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-xl-start text-center">
                                     Logo Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Website Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Value Added Services
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Unlimited Logo Design Concepts</li>
                                     <li>Unlimited Revisions</li>
                                     <li>Icon Design</li>
                                     <li>All Final File Formats</li>
                                     
                                   </ul>
                                 </div>
     
                                 <div className="col-md-6 mt-md-0 mt-4">
                                   <h2 className="fw-bold text-md-start text-center">
                                     Stationary Design
                                   </h2>
                                   <ul className="mt-4">
                                     <li>Buisness Card, Letterhead, Envelope</li>
                                     <li>Ms Word Letterhead</li>
                                   </ul>
                                 </div>
     
     
                             </div>
                           </div>
                           <div className="col-md-4 text-blue text-center my-auto">
                               <h4>Combo Package</h4>
                               <div className="my-4">
                                    <Link to="/contact-us" className='btn btn-1'>
                                        Get a Qoute
                                    </Link>
                                </div>
                               <p>LIMITED TIME OFFER</p>
                               <small>Want To Discuss</small>
                               <div className="mt-4">
                                 <Link to="/contact-us" className="btn btn-1">Order Now</Link>
                               </div>
                           </div>
                         </div>
                         </div>
                         </div>



                     </div>
                     </>
                     }  



        </div>
      </section>
    </>
  )
}
