import React from 'react'
// import $ from 'jquery'; 
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import '@fancyapps/fancybox';



export default function Website(props) {

    // useEffect(() => {
    //     try {
    //         $('[data-fancybox="gallery"]').fancybox();

    //     } catch (error) {
    //         console.error('Error initializing Fancybox:', error);
    //     }
    // }, []);


  return (
    <>
        

            <div className="row gx-4">
                <div className=" col-md-4 mt-4">
                    <div className="folio-card mb-3" style={{background:`url(${props.image1})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                        <div className="card-overlay">
                            <a href={props.image1} data-fancybox="gallery" className='view-btn' rel="noreferrer">
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <a href={props.link1} target='_blank' rel='noreferrer' className='my-3 text-center'>
                        <h5 className='fw-bold'>{props.name1}</h5>
                    </a>
                    <div className='text-center'>
                    <a href={props.link1} target='_blank' rel='noreferrer' className='btn btn-outline-dark my-2 text-center px-3'>
                        <i className="fa-regular fa-eye fs-6"></i> Live Demo
                    </a>
                    </div>
                </div>
                
                <div className=" col-md-4 mt-4">
                    <div className="folio-card mb-3" style={{background:`url(${props.image2})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                        <div className="card-overlay">
                            <a href={props.image2} data-fancybox="gallery" className='view-btn'  rel="noreferrer">
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <a href={props.link2} target='_blank' rel='noreferrer' className='my-3 text-center'>
                        <h5 className='fw-bold'>{props.name2}</h5>
                    </a>
                    <div className='text-center'>
                    <a href={props.link2} target='_blank' rel='noreferrer' className='btn btn-outline-dark my-2 text-center px-3'>
                        <i className="fa-regular fa-eye fs-6"></i> Live Demo
                    </a>
                    </div>

                </div>


                <div className=" col-md-4 mt-4">
                    <div className="folio-card mb-3" style={{background:`url(${props.image3})`,backgroundSize:'cover',backgroundPosition:'center',objectFit:'cover'}}>
                        <div className="card-overlay">
                            <a href={props.image3}  data-fancybox="gallery" className='view-btn' rel="noreferrer">
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <a href={props.link3} target='_blank' rel='noreferrer' className='my-4 text-center'>
                        <h5 className='fw-bold'>{props.name3}</h5>
                    </a>
                    <div className='text-center'>
                    <a href={props.link3} target='_blank' rel='noreferrer' className='btn btn-outline-dark my-2 text-center px-3'>
                        <i className="fa-regular fa-eye fs-6"></i> Live Demo
                    </a>
                    </div>

                    
                </div>

            </div>
            

      

    
    </>
  )
}
