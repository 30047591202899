import React from 'react'
import { Link } from 'react-router-dom';
export default function Navbar() {

  
  window.addEventListener('scroll',function(){
    var navbar = document.querySelector('.navbar');
    if(this.scrollY > 160){
      navbar.style.position = 'fixed';
      navbar.style.top = 0;
      navbar.style.backgroundColor = '#1d88ca';
      navbar.style.boxShadow = '0 0 15px #026c9a';
      navbar.style.transition = 'all 0.3s ease-in-out';
    }else{
      navbar.style.position = 'absolute';
      navbar.style.top = '50px';
      navbar.style.backgroundColor = 'transparent';
      navbar.style.boxShadow = 'none';
      navbar.style.transition = 'all 0.3s ease-in-out';

    }
    var footer = this.document.querySelector('.footer');
    if(this.scrollY >= footer.offsetTop){
      navbar.style.position = 'absolute';
      navbar.style.top = '50px';
      navbar.style.backgroundColor = 'transparent';
      navbar.style.boxShadow = 'none';
      navbar.style.transition = 'all 0.3s ease-in-out';

  }

  });

  return (
    <>
      <header className="p-2 d-lg-block d-none ">
        <div className="container">
            <div className="row justify-content-around align-items-center">
                <div className="col-md-8">
                    <p className="my-auto"><span className="text-white">WhatsApp/Call:</span> <Link to="https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0" target="_blank">+923133876798</Link> <span className="text-white">Or</span> <span className="text-white"> Email Us:</span> <Link to="mailto:media.production.art@gmail.com" target="_blank">media.production.art@gmail.com</Link></p>
                </div>
                <div className="col-md-4 my-auto">
                <div className="text-end header-icons">
                    <Link to="https://www.linkedin.com/in/mediaproductionart/" target="_blank"><i className="fa-brands fa-linkedin "></i></Link>
                    <Link to="https://www.instagram.com/mediaproductionart/" target="_blank"><i className="fa-brands fa-instagram"></i></Link>
                    <Link to="https://www.facebook.com/mediaproductionart" target="_blank"><i className="fa-brands fa-facebook-f"></i></Link>
                    <Link to="https://www.youtube.com/@mediaproductionarteducation" target="_blank"><i className="fa-brands fa-youtube "></i></Link>
                    <Link to="https://www.tiktok.com/@mediaproductionart" target="_blank"><i className="fa-brands fa-tiktok"></i></Link>
                </div>
                </div>
            </div>
        </div>
    </header>
    
    {/* header ends here..................   */}

    {/* navigation starts here......................   */}
          <nav className="navbar navbar-expand-xl navbar-dark ">
            <div className="container">
              <Link className="navbar-brand" to="/"><img src="static/images/Logos.svg" alt=""  loading="lazy" className="img-fluid"></img></Link>

                {/* Monitor menu ........................  */}

                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto monitor-menu">
                  
                  <li className="nav-item link">
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                    <li className="nav-item link">
                    <Link className="nav-link" to="/about">About Us</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link" to="/services">Services</Link>
                  </li>
                  <li className="nav-item drop-toggle">
                    <Link className="nav-link link " to="/portfolio">
                      Portfolio <i className="fa-solid fa-angle-down my-auto" style={{fontSize:'14px'}}></i>
                    </Link>
                    <ul className="drop-menu" >
                      <li><Link className="drop-item" to="/web-development">Website Development</Link></li>
                      <li><Link className="drop-item" to="/graphic-design">Graphic Designing</Link></li>
                      <li><Link className="drop-item" to="/packaging-design">Packaging Designing</Link></li>
                      <li><Link className="drop-item" to="/logo-design">Logo Designing</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link" to="/packages">Packages</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link" to="/career">Career</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link" to="https://edu.mediaproductionart.com/" target='_blank'>Education</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link" to="/contact-us">Contact</Link>
                  </li>
                </ul>
                {/* Monitor menu ........................  */}


                {/* mobile menu ........................  */}
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto mobile-menu ">
                  <div className="text-end close d-xl-none d-block">
                    <Link className="close-menu closed"><i className="fa-solid fa-xmark text-white fs-5"></i></Link>
                  </div>
                  
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="/">Home</Link>
                  </li>
                    <li className="nav-item link">
                    <Link className="nav-link off-link" to="/about">About Us</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="/services">Services</Link>
                  </li>
                  <li className="nav-item drop-toggle">
                    <Link className="nav-link link on-link">
                      Portfolio <i className="fa-solid fa-angle-down my-auto" style={{fontSize:'14px'}}></i>
                    </Link>
                    <ul className="drop-menu" >
                      <li><Link className="drop-item off-link" to="/portfolio">All </Link></li>
                      <li><Link className="drop-item off-link" to="/web-development">Website development</Link></li>
                      <li><Link className="drop-item off-link" to="/graphic-design">Graphic Design </Link></li>
                      <li><Link className="drop-item off-link" to="/packaging-design">Packaging Design</Link></li>
                      <li><Link className="drop-item off-link" to="/logo-design">Logo Design </Link></li>
                    </ul>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="/packages">Packages</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="/career">Career</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="https://edu.mediaproductionart.com/" target='_blank'>Education</Link>
                  </li>
                  <li className="nav-item link">
                    <Link className="nav-link off-link" to="/contact-us">Contact</Link>
                  </li>
                </ul>
                {/* mobile menu ........................  */}


                <div className="d-flex ">
                    <Link className="mx-2 bars d-xl-none d-inline-flex ">
                      <img src="static/images/bar.svg" alt=""  loading="lazy" className="img-fluid" width="20px"></img>
                  </Link>
                  <Link to="/contact-us" className="btn btn-2 mt-md-0 mt-4 fw-bold d-lg-inline-flex d-none">
                        Get a Qoute
                  </Link>
                </div>
            
            </div>
          </nav>
          <div className="overlay"></div> 
    </>
  )
}
