import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

export default function Career() {
    const [isVerified, setIsVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate(); // Using useNavigate hook

    const redirect = () => {
        // Navigate to the desired URL
        navigate('/');
    };

    const handleRecaptchaChange = (token) => {
        console.log('reCAPTCHA token:', token);
        setIsVerified(true); // Set verified state to true
    };
    function changeValue(){
        const inputs = document.querySelectorAll('.career-input');
      inputs.forEach(function(e){
          e.value = ""
      });

      setTimeout(() => {
        redirect(); // Call the redirect function inside setTimeout
    }, 2000);
    }

    const form = useRef();

    function sendEmail(e) {
        e.preventDefault();

        if (!isVerified) {
            setErrorMessage('Please verify reCAPTCHA before sending the message.');
            return;
        }

        emailjs
        .sendForm('service_astjhkk', 'template_uluinmd', form.current, {
          publicKey: 'ZIW5UmK6LLnDfWjyj',
        }).then(() => {
                setErrorMessage('Thank you! Your request has been submitted successfully. We will respond to you soon.');
                changeValue();
            })
            .catch((error) => {
                setErrorMessage(`Sorry, because of some issue your message could not be sent: ${error}`);
            });
    };

    return (
        <>
            <section className="career-banner">
                <div className="container text-center">
                    <h1 className='text-white '>Career</h1>
                    <h6 className='text-white mt-4  px-lg-5 px-0 mx-lg-5 mx-0'>Explore dynamic career opportunities in software development with our innovative team. Join us today to embark on an exciting journey of growth and innovation.</h6>
                </div>
            </section>

            <section className="padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 p-5 shadow" style={{ borderRadius: '20px' }}>
                            <div className='text-md-start text-center'>
                                <span className='bg-blue fs-4 fw-bold text-white p-2'>Join Our Innovative Team!</span>
                                <h2 className='my-4 fw-bold fs-1 '>Submit Your Info</h2>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="">
                                    <div className="">
                                        <input type="text" className='career-input' placeholder="Job Title you want to apply for*" required name='message'></input>
                                    </div>
                                    <div className="mt-4">
                                        <input type="text" className='career-input' placeholder="Full Name*" required name='from_name' ></input>
                                    </div>
                                    <div className=" mt-4">
                                        <input type="email" className='career-input' placeholder="Email*" required name='from_email'></input>
                                    </div>
                                    <div className=" mt-4">
                                        <input type="tel" className='career-input' placeholder="Phone*" required name='from_phone' ></input>
                                    </div>
                                    <div className="my-3">
                                        <ReCAPTCHA
                                            sitekey="6LdJcMApAAAAAEU7BVWoqoVRUKHWoScN9r_wF8yc"
                                            onChange={handleRecaptchaChange}
                                            className='recaptcha'
                                        />
                                    </div>
                                    <div className='my-3 text-md-start text-center'>
                                        {errorMessage && (
                                        <p className='fw-bold'>
                                            {errorMessage}
                                            {setTimeout(() => setErrorMessage(''), 5000)}
                                        </p>
                                    )}
                                    </div>
                                    <div className=" mt-4">
                                        <input type="submit" className='btn btn-1' value="Send"></input>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-lg-5 mt-lg-0 mt-4" >
                                <div className='bg-light p-4 py-5' style={{borderRadius:'10px'}}>
                                    <span className="fs-5 bg-blue p-2 text-white">
                                        Graphic Designer
                                    </span>
                                    <p className="mt-4">
                                    The ideal graphic designer should have proficiency in Adobe Photoshop, Illustrator, and InDesign, a strong portfolio, excellent communication skills, attention to detail, and the ability to meet deadlines. A bachelor's degree in graphic design or a related field is preferred.
                                    </p>

                                    <span className="fs-5 bg-blue p-2 text-white mt-5">
                                        Website Developer
                                    </span>
                                    <p className="mt-4">
                                    The ideal web developer should possess proficiency in HTML, CSS, JavaScript, and related frameworks like React or Angular. They should have experience with server-side languages like Python, Node.js or PHP, familiarity with version control systems like Git, and the ability to create responsive and visually appealing web interfaces. A bachelor's degree in computer science or a related field is preferred.
                                    </p>

                                    <span className="fs-4 bg-blue p-2 text-white mt-5">
                                        SEO Specialist
                                    </span>
                                    <p className="mt-4">
                                    The ideal SEO specialist should have a strong understanding of search engine algorithms, keyword research, and on-page and off-page optimization techniques. They should be proficient in SEO tools like Google Analytics and SEMrush, and possess excellent analytical and problem-solving skills. Experience with website auditing and content optimization is preferred, along with staying updated on industry trends and algorithm changes. A bachelor's degree in marketing, communications, or a related field is advantageous.
                                    </p>
                                </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
