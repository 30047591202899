import React from 'react'

export default function Loader() {
    
  return (
    <div className='loader'>
            <div>
                <img src="static/images/Logo.webp" alt=""></img>
            </div>
        <div className="loading mt-5">
            <div className="loading-inner"></div>
        </div>
    </div>
  )
}
