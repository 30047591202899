import React from 'react'
// import $ from 'jquery'; // Import jQuery first


import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'; // Import Fancybox CSS
import '@fancyapps/fancybox'; // Import Fancybox library




export default function Portfolio(props) {

    // useEffect(() => {
    //     try {
    //         $('[data-fancybox="gallery"]').fancybox();

    //     } catch (error) {
    //         console.error('Error initializing Fancybox:', error);
    //     }
    // }, []);
  return (
    <>
     
           
            <div className="row gx-4">
                <div className=" col-md-4 mt-4">
                    <div className="folio-card" style={{background:`url(${props.image1})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                        <div className="card-overlay">
                            <a href={props.link1} data-fancybox="gallery" className='view-btn'>
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <div className='my-3 text-center'>
                        <h5 className='fw-bold'>{props.name1}</h5>
                    </div>
                </div>
                
                <div className=" col-md-4 mt-4">
                    <div className="folio-card " style={{background:`url(${props.image2})`,backgroundSize:'cover',backgroundPosition:'center'}}>
                        <div className="card-overlay">
                            <a href={props.link2} data-fancybox="gallery" className='view-btn'>
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <div className='my-3 text-center'>
                       
                        <h5 className='fw-bold'>{props.name2}</h5>
                        
                    </div>
                </div>


                <div className=" col-md-4 mt-4">
                    <div className="folio-card" style={{background:`url(${props.image3})`,backgroundSize:'cover',backgroundPosition:'center',objectFit:'cover'}}>
                        <div className="card-overlay">
                            <a href={props.link3} data-fancybox="gallery" className='view-btn'>
                                <i className="fa-regular fa-eye"></i> <span>Preview</span>
                            </a>
                        </div>
                    </div>
                    <div className='my-3 text-center'>
                        <h5 className='fw-bold'>{props.name3}</h5>
                    </div>
                </div>

            </div>
        
    </>
  )
}
