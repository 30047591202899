import React, { useState } from 'react'
import Banner from './Banner'
import Cards from './Cards'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Gallery from './Gallery';
import { Link } from 'react-router-dom';
import Servicecard from './Servicecard';


export default function Home() {
  const [Counter,setCounter] = useState(false)
  
  return (
    <>
    <Banner/>
     {/* banner ends here..................   */}
        

    

     {/* section starts here....................   */}
              <section className="padding " >
                <div className="container">
                  <div className="row">

                    <div className="col-lg-6 text-md-start text-center">
                      
                      
                      <h4 className="fw-bold text-blue">
                        Who We Are  
                      </h4>
                      <p className="mt-2">Media Production Art is a graphic and website design company with over a decade of experience in creating a brand identity for our clients. We use creative strategies to develop all aspects of a client’s brand, from website development to logo design and Digital Marketing solutions.</p>
                      <h4 className="fw-bold text-blue">
                        Our Approach
                      </h4>
                      <p className="mt-2">We believe that a strong brand identity is essential for any business. Your brand is what sets you apart from your competitors and it’s what will make customers remember you. That’s why we take a comprehensive approach to brand development, working on all aspects of your brand to communicate your goals and values.</p>
                      <div className="mt-4 ">
                       
                          <Link to="/about" className="btn-1 btn my-auto">Read More</Link>
                       
                       
                      </div>
                    </div>
                  
                  

                    <div className="col-md-6 mt-md-0 mt-3 text-center">
                      <img src="static/images/about/MPA Website_Design-03.webp" alt=""  loading="lazy"
                      className='img-fluid'></img>
                    </div>

                  </div>
                </div>
                      
              </section>
     {/* section end here....................   */}
          <Servicecard/>


      

        <ScrollTrigger onEnter={()=> setCounter(true)} onExit={()=> setCounter(false)}>
        <section className='padding bg-blue ' id="counter">
          <div className="container">
            <div className='py-5 text-center mt-4'>
              <h1 className="fw-bold text-white">Grow with award-winning support <br></br>and a thriving community behind you.</h1>
              <p className="mt-4 text-white">Media Production Art's scale and capability has grown over a long history of successfully serving our customers.</p>
            </div>
            <div className="row">
              <div className="col-md-4 text-center">
                 {Counter && <h1 className='fw-bold text-white'><CountUp start={1000} end={15725} duration={3} /><sup>+</sup></h1>}
                 <p className='text-white'>Projects Done</p>
              </div>
              <div className="col-md-4 text-center mt-md-0 mt-4">
                 {Counter && <h1 className='fw-bold text-white'><CountUp start={0} end={100} duration={3} /><sup>%</sup></h1>}
                 <p className='text-white'>Success Rate</p>

              </div>
              <div className="col-md-4 text-center mt-md-0  mt-4">
                 {Counter && <h1 className='fw-bold text-white'><CountUp start={0} end={73623} duration={3} /><sup>+</sup></h1>}
                 <p className='text-white'>Happy Clients</p>

              </div>
            </div>

          </div>
        </section>
        </ScrollTrigger>


        <section className='padding' >
          <div className="container">
            <div className='py-5 text-md-start text-center'>
              <h1 className="fw-bold lined2 text-center">Industries We Serve</h1>
            </div>
            <Cards/>

          </div>
        </section>
        
        <section className='padding '>
          <div className="container py-5">
              <div className="row">
                <div className="col-md-6">
                    <img src="static/images/MPA Website_Design-02.webp" alt=""  loading="lazy" className="img-fluid"></img>
                </div>
                <div className="col-md-6 my-auto px-md-4 mt-md-auto mt-4 text-md-start text-center">
                    <h2 className="fw-bold lined2">We achieve your goals effectively </h2>
                    <p className="mt-4">
                    At Media Production Art, our mission is to achieve your goals effectively. With a commitment to excellence and a focus on results-driven solutions, we empower our clients to reach new heights of success. Whether you're aiming to increase sales, enhance brand visibility, or streamline operations, our dedicated team is here to make it happen. Through strategic planning, innovative thinking, and personalized approaches, we work tirelessly to deliver tangible outcomes that exceed expectations. Partner with us and experience the difference as we collaborate to turn your aspirations into achievements.
                    </p>
                    <p>
                    We understand that every project is unique.  We don't believe in a "one-size-fits-all" approach.  Our team takes the time to thoroughly understand your specific goals, target audience, and brand identity.  This allows us to craft a customized media production strategy that aligns perfectly with your needs and delivers measurable results.  By combining our expertise with your vision, we create impactful content that resonates with your audience and drives them to take action.
                    </p>
                    <div className=''>
                        <Link to="/about" className="btn btn-1 ">More</Link>

                    </div>
                </div>  
              </div>
          </div>
        </section>

        {/* section...........................  */}
          <Gallery/>
        {/* section...........................  */}




        
      {/* section ends here..............  */}

    </>
  )
}
