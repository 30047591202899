import React from 'react'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Servicecard() {
  return (
    <>
     {/* section starts here..............  */}
     <section className='padding bg-light' >
            <div className="container">
              <div className='text-center'>
                <h1 className='fw-bold lined2 '>Our Services</h1>
              </div>
              <div className="row mt-5 ">
                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>
                    <div className="service-card">
                      <div>
                      <LazyLoadImage
                            key={'image1'}
                            alt={'image'}
                            src={'static/images/services/1.webp'}
                            className='img-fluid object-fit-cover blur-effect'
                            effect="blur"
                            placeholderSrc={'static/images/services/1.webp'}
                      />
                      </div>
                     
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Corporate Identity & Branding</p>
                            <p className=''>Seamlessly Blend Identity and Stationery Design for a Cohesive Brand Experience.</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-6 mt-5">
                    <Link to='/services' className='service'>
                    <div className="service-card">
                    <LazyLoadImage
                          key={'image2'}
                          alt={'image2'}
                          src={'static/images/services/2.webp'}
                          effect="blur"
                          placeholderSrc={'static/images/services/2.webp'}
                          className='img-fluid object-fit-cover'
                    />
                       
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Custom Signs and Graphics</p>
                            <p className=''>Transform Your Space with Custom Signs and Graphics Tailored to Your Brand.</p>
                        </div>
                    </div>
                    </Link>

                </div>
                <div className="col-lg-4 col-md-6 mt-5">
                   <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                            key={'image3'}
                            alt={'image3'}
                            src={'static/images/services/3.webp'}
                            effect="blur"
                            placeholderSrc={'static/images/services/3.webp'}
                            className='img-fluid object-fit-cover'
                      />
                       
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Commercial Print</p>
                            <p className=''>Elevate Your Brand with Premium Commercial Printing Services.</p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                              key={'image4'}
                              alt={'image4'}
                              src={'static/images/services/4.webp'}
                              effect="blur"
                              placeholderSrc={'static/images/services/4.webp'}
                              className='img-fluid object-fit-cover'
                        />
                       
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Prime labels and Packaging</p>
                            <p className=''>Unveil Excellence with Prime Labels and Packaging Solutions Tailored to Perfection.</p>
                        </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                              key={'image5'}
                              alt={'image5'}
                              src={'static/images/services/5.webp'}
                              effect="blur"
                              placeholderSrc={'static/images/services/5.webp'}
                              className='img-fluid object-fit-cover'
                        />
                          
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Promotional Marketing</p>
                            <p className=''>Ignite Your Brand's Reach with Strategic Promotional Marketing Solutions.</p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                            key={'image6'}
                            alt={'image6'}
                            src={'static/images/services/6.webp'}
                            effect="blur"
                            placeholderSrc={'static/images/services/6.webp'}
                            className='img-fluid object-fit-cover'
                      />
                       
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Website Designing</p>
                            <p className=''>Elevate Your Brand with Expert Website Design Services Tailored to Your Goals.</p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                    <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                            key={'image7'}
                            alt={'image7'}
                            src={'static/images/services/7.webp'}
                            effect="blur"
                            placeholderSrc={'static/images/services/7.webp'}
                            className='img-fluid object-fit-cover'
                      />
                        
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Website Development</p>
                            <p className=''>Revolutionize Your Online Presence with Expert Website Development.</p>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                            key={'image8'}
                            alt={'image8'}
                            src={'static/images/services/8.webp'}
                            effect="blur"
                            placeholderSrc={'static/images/services/8.webp'}
                            className='img-fluid object-fit-cover'
                      />
                        
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>SEO Optimization</p>
                            <p className=''>Harness the Power of SEO to Propel Your Website to the Top of Search Results.</p>
                        </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-4 col-md-6 mt-5">
                  <Link to='/services' className='service'>

                    <div className="service-card">
                      <LazyLoadImage
                            key={'image9'}
                            alt={'image9'}
                            src={'static/images/services/9.webp'}
                            effect="blur"
                            placeholderSrc={'static/images/services/9.webp'}
                            className='img-fluid object-fit-cover'
                      />
                        
                        <div className='card-box p-3'>
                            <p className='text-blue'><i className="fa-solid fa-plus fs-2 rotater"></i></p>
                            <p className='fw-bold mt-4'>Digital Marketing:</p>
                            <p className=''>Accelerate Your Business with Targeted Digital Marketing Solutions</p>
                        </div>
                    </div>
                  </Link>
                </div>

                
                
              </div>
            </div>
        </section>


    </>
  )
}
