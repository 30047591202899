import React from 'react'
import Website from './Website'



export default function Webpf(props) {
  return (
    <>
    
            <section className="padding website-banner" style={{background:`linear-gradient(rgba(2, 108, 154, 0.8), rgba(2, 108, 154, 0.5)), url(${props.url})`}}>

            <div className="container">
                <h1 className='text-white lined text-center'>{props.title}</h1>
            </div>
        </section>

        <section className='padding'>
            <div className="container text-center">
                <h1 className='text-blue lined3  my-5'>Web Development Portfolio</h1>
                
                <Website heading="" link1="https://portfolio.mediaproductionart.com/big%20red%20jelly/index.html" image1="static/images/portfolio/website/1.webp" name1="Media Red Jelly" link2="https://portfolio.mediaproductionart.com/Logo%20design%20Valley/index.html" image2="static/images/portfolio/website/2.webp" link3="https://portfolio.mediaproductionart.com/law2/index.html" name2="Logo Design Media" image3="static/images/portfolio/website/3.webp" name3="Law Firm 2"/>
                
                <Website heading="" link1="https://portfolio.mediaproductionart.com/logo%20Design%20net/index.html" image1="static/images/portfolio/website/4.webp" name1="Logo Media" link2="https://portfolio.mediaproductionart.com/Law%20firm/index.html" image2="static/images/portfolio/website/5.webp" link3="https://portfolio.mediaproductionart.com/law3/index.html" name2="Law Firm" image3="static/images/portfolio/website/6.webp" name3="Law Firm 3"/>

                <Website heading="" link1="https://portfolio.mediaproductionart.com/wp/resturant/" image1="static/images/portfolio/website/7.webp" name1="Restaurant WrdPress" link2="https://portfolio.mediaproductionart.com/wp/beauty/" image2="static/images/portfolio/website/8.webp" link3="https://portfolio.mediaproductionart.com/wp/finance/" name2="Beauty WordPress" image3="static/images/portfolio/website/9.webp" name3="Finance WordPress"/>

                <Website heading="" link1="https://www.roastedroseflorals.com/" image1="static/images/portfolio/website/10.webp" name1="Floral Studio - Wix" link2="https://www.leroysfloristflorida.net/" image2="static/images/portfolio/website/11.webp" link3="https://portfolio.mediaproductionart.com/Builder/index.html" name2="Leroy's Florist - Squarespace" image3="static/images/portfolio/website/12.webp" name3="Construction Website - Custom"/>

                <div className='mt-5'>
                    <a href="https://portfolio.mediaproductionart.com/" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
            </div>
        </section>

    </>
  )
}
