import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';




export default function Contact() {
    
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const navigate = useNavigate(); // Using useNavigate hook

  const redirect = () => {
      // Navigate to the desired URL
      navigate('/');
  };

  const handleRecaptchaChange = (token) => {
      console.log('reCAPTCHA token:', token);
      setIsVerified(true); // Set verified state to true
  };
  function changeValue(){
      const inputs = document.querySelectorAll('.career-input');
    inputs.forEach(function(e){
        e.value = ""
    });

    setTimeout(() => {
      redirect(); // Call the redirect function inside setTimeout
  }, 2000);
  }

  const form = useRef();

  function sendEmail(e) {
      e.preventDefault();

      if (!isVerified) {
          setErrorMessage('Please verify reCAPTCHA before sending the message.');
          return;
      }

      emailjs
      .sendForm('service_astjhkk', 'template_ezwf7fq', form.current, {
        publicKey: 'ZIW5UmK6LLnDfWjyj',
      }).then(() => {
              setErrorMessage('Thank you! Your request has been submitted successfully. We will respond to you soon.');
              changeValue();
          })
          .catch((error) => {
              setErrorMessage(`Sorry, because of some issue your message could not be sent: ${error}`);
          });
  };

  return (  
    <>
      <section className='padding contact-banner'>
        <div className="container">
            <h1 className='text-white text-center '>Let's Connect</h1>
            <h6 className='text-white text-center mt-3  px-lg-5 px-0 mx-lg-5 mx-0 '>Learn more about growing your business through digital marketing solutions!</h6>
            <h6 className='text-white text-center mt-3  px-lg-5 px-0 mx-lg-5 mx-0 '>Drop us a line using the form, or reach out directly by phone or email.</h6>
            
        </div>
      </section>

   
      <section className="padding">
        <div className="container">
            

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3604.9323331912747!2d68.36001921045082!3d25.373584224441284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c705f5c9f56ab%3A0x84542d1b7f63f413!2sMedia%20Production%20Art!5e0!3m2!1sen!2s!4v1709902724005!5m2!1sen!2s" width="100%" height="300" title='MPA Location' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  

        <div className="container">
            <h1 className="text-center my-5 text-blue">Get In Touch With Us</h1>
            <div className="row">
                <div className="col-lg-6 my-auto">
                    <div className='form'>
                    <div>
                        <h3 className='fw-bold'>We’ll Be Glad To Assist You!</h3>
                        <p>If there is anything you want to talk about, please do not hesitate to contact us.</p>
                    </div>
                        <form ref={form} action="" className='' onSubmit={sendEmail}>
                        <div className='mt-3'>
                                <input type="text" required className='input w-100' placeholder="Name" name="from_name" />
                            </div>
                            <div className='mt-3'>
                                <input type="tel"  className='input w-100' placeholder="Phone Number" name="from_phone"/>
                            </div>
                            <div className='mt-3'>
                                <input type="email" required className='input w-100' placeholder="Email Address" name="from_email" />
                            </div>
                            
                            <div className='mt-3'>
                                <textarea type="text" required className='input w-100 ' rows="5" placeholder="Your Message" name="message" />
                            </div>
                            <div className="my-3">
                                <ReCAPTCHA
                                      sitekey="6LdJcMApAAAAAEU7BVWoqoVRUKHWoScN9r_wF8yc"
                                      onChange={handleRecaptchaChange}
                                      className='recaptcha'
                                      style={{width:'300px'}}
                                    />
                              </div>
                              <div className='my-3'>
                                        {errorMessage && (
                                        <p className='fw-bold text-success'>
                                            {errorMessage}
                                            {setTimeout(() => setErrorMessage(''), 5000)}
                                        </p>
                                )}
                              </div>
                            <button className='btn btn-1 mt-3' type="submit" disabled={!isVerified}>Send Message</button>
                            
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 mt-lg-0 mt-4">
                    <div className='p-5' style={{boxShadow:'0 0 30px rgba(0,0,0,0.1)',borderRadius:'10px'}}>
                            <span className='bg-blue p-2 fs-4 text-white'>Visit Us</span>
                   
                    <div className='mt-3'>
                                <div className="mt-3 d-flex">
                                  
                                  <div className='my-atuo'>

                                  <img src="static/images/flags2.webp" alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </div>
                                  </div>
                          <p className="pt-0 mt-2"><span className='fw-bold'>Phone: </span><a href='https://api.whatsapp.com/send/?phone=18135305507&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className=''>+1 (813) 530-5507</a></p>

                          <p className="my-0 pb-1"><span className='fw-bold'>Email: </span> <a href="mailto:design@tajanna.com" target='_blank' rel="noreferrer" className=''>design@tajanna.com</a></p>

                          <div className='mt-2'>
                          <strong>Office Address: </strong>
                            <p className="mt-1">
                              <p className='mb-0 pb-0'>19046 Bruce B Downs Blvd</p>
                              <p className='mb-0 pb-0'>Ste B6 #844</p>
                              Tampa, FL 33647
                            </p>
                    </div>
                          
                        

                    </div>
                    
                         
                          
                          
                    
                    <div className='mt-3'>
                           <div className="">
                            <img src="static/images/flag1.webp" alt=""  loading="lazy" className='mx-1 img-fluid' width="30px"></img>        
                          </div>
                          
                          <p className="mt-1 mb-0 py-1"><span className='fw-bold'>Phone: </span><a href='https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className=''>+92 313 387-6798</a></p>

                          <p className="mt-1 mb-0 pb-1"><span className='fw-bold'>Email: </span> <a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='d-sm-block d-none'>media.production.art@gmail.com</a>
                          <a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='d-sm-none d-block'>media.production.art @gmail.com</a>
                          
                          </p>       
                          <p className="mt-2">
                              <strong>Office Address :</strong>
                              <p className='mb-0 pb-0'>First Floor، Raeesa Apartment</p>
                              <p className='mb-0 pb-0'>Unit 7 Latifabad,</p>
                              Hyderabad Sindh, Pakistan
                            </p>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
        </div>
      </section>  

      

    </>
  )
}
