import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from "./Components/About";
import Services from "./Components/Services";
import Folios from "./Components/Folios";
import Designpf from "./Components/Designpf";
import Logopf from "./Components/Logopf";
import Webpf from "./Components/Webpf";
import Packpf from "./Components/Packpf";
import Contact from "./Components/Contact";
import Packages from "./Components/Packages";
import Wpicon from './Components/Wpicon';
import Loader from './Components/Loader';
import Career from './Components/Career';

document.querySelector('.body').style.overflow = 'hidden';



  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant' // Add smooth behavior
      });
      
    }, [pathname]);

    return null;
  }

function App() {
  const [loading, setLoading] = useState(true);
  window.addEventListener('load',function(){
    setLoading(false)  
    document.querySelector('.body').style.overflow = 'auto';
  });   

  return (
    <>
     <Router>
      <ScrollToTop/>
      
      {loading && <Loader/>}
  
      <Navbar />
      <Wpicon />
      <Routes>
        <Route path="/" element={<Home />} key="home" />
        <Route path="/about" element={<About title="About Us" />} key="about" />
        <Route path="/services" element={<Services title="Service page" />} />
        <Route path="/portfolio" element={<Folios title="Our Portfolio" url="static/images/portfolio/all.webp" />} />
        <Route path="/web-development" element={<Webpf title="Website Development Portfolio" url="static/images/portfolio/website.webp" />} />
        <Route path="/graphic-design" element={<Designpf title="Graphic Design Portfolio" url="static/images/portfolio/logo.webp" />} />
        <Route path="/logo-design" element={<Logopf title="Logo Design Portfolio" url="static/images/portfolio/logo.webp" />} />
        <Route path="/packaging-design" element={<Packpf title="Packaging Design Portfolio" url="static/images/portfolio/packing.webp" />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
    </>
    
    
  );
};



export default App;
