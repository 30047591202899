import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import 'swiper/css';
import 'swiper/css/pagination';
import ReCAPTCHA from 'react-google-recaptcha';
import Testimonials from './Testimonials';



export default function Footer() {


    const [isVerified, setIsVerified] = useState(false);

    const handleRecaptchaChange = (token) => {
      console.log('reCAPTCHA token:', token);
      setIsVerified(true); // Set verified state to true
    };

 
    const form = useRef();

    function changeValue(){
        const inputs = document.querySelectorAll('.foot-form');
      inputs.forEach(function(e){
          e.value = ""
      })
    }
    
    const sendEmail = (e) => {
      e.preventDefault();
  

      emailjs
        .sendForm('service_astjhkk', 'template_ezwf7fq', form.current, {
          publicKey: 'ZIW5UmK6LLnDfWjyj',
        })
        .then(
          () => {
            alert('Thankyou! Your Message has been sent successfully we will respond you soon!');
            changeValue();
          },
          (error) => {
            alert('Sorry Because of Some Issue your Message could not be sent', error.text);
          },
        );
    };   

    
    

  return (
    <>
        <Testimonials/>

  

        <section className="padding bg-gray footer" >
            <div className="container">
                <div className="row">
                  <div className="col-md-8">
                            <h1 className='fw-bold text-blue text-md-start text-center'>
                            Let MPA help you deliver
                            exceptional customer experiences.
                            </h1>
                  </div>
                  <div className="col-md-4 text-md-end text-center my-auto mt-md-auto mt-4">
                    <div className='my-auto'>
                      <Link to="/contact-us" className="btn btn-1 fw-bold p-3 px-5 fs-6">Contact Us</Link>
                    </div>
                  </div>
                </div>
            </div>
        </section>
        

      <footer className="padding bg-blue ">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 text-md-start text-center" >
                        <Link to="/">
                            <img src="static/images/Logos.svg"  alt="" loading="lazy" className='img-fluid footer-logo' ></img>
                        </Link>
                      
                        <h4 className='text-white fw-bold mt-4'>Let's Chat</h4>
                                <div className="mt-1 d-flex">
                                  <div className='my-auto'>
                                  <img src="static/images/footer-02.svg"  alt="" loading="lazy" className='img-fluid' width="15px"></img>
                                  </div>
                                  <div className='my-atuo'>

                                  <img src="static/images/flag1.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </div>
                                  </div>
                            <div className='mt-2 text-start'>

                                <div className="my-auto">
                                  <p className='text-white'> First Floor، Raeesa Apartment، Unit 7 Latifabad, Hyderabad</p>
                                  <div className="d-flex text-start my-0 py-0">

                                <div className="">
                                    <img src="static/images/footer-03.svg"  alt="" loading="lazy" className='img-fluid' width="20px"></img>
                                </div>
                                <div className="my-auto mx-2">
                                      <a href='https://api.whatsapp.com/send/?phone=923133876798&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className='text-white '>(+92) 313 387-6798</a>
                                </div>
                                </div>

                                <div className="mt-3 d-flex">
                                  <div className='my-auto'>
                                  <img src="static/images/footer-02.svg"  alt="" loading="lazy" className='img-fluid' width="15px"></img>
                                  </div>
                                  <div className='my-atuo'>

                                  <img src="static/images/flags2.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </div>
                                  </div>

                                <p className='text-white mt-2'>19046 Bruce B Downs Blvd Ste B6 #844 Tampa, FL 33647</p></div>
                                <div className="d-flex text-start">

                                <div className="">
                                    <img src="static/images/footer-03.svg"  alt="" loading="lazy" className='img-fluid' width="20px"></img>
                                </div>
                                <div className="my-auto mx-2">
                                      <a href='https://api.whatsapp.com/send/?phone=18135305507&text&type=phone_number&app_absent=0' target='_blank' rel="noreferrer" className='text-white my-auto'>+1 (813) 530-5507</a>
                                    
                                </div>
                                </div>

                            </div>

                            

                           
                            

                    </div>

                    <div className="col-lg-2 col-md-6 px-4 mt-4 mt-md-0" >
                        <h4 className="fw-bold my-4 text-white">
                            Company
                        </h4>
                        <ul className='ul-v'>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/portfolio">Portfolio</Link></li>
                            <li><Link to="/packages">Packages</Link></li>
                            <li><Link to="/career">Career</Link></li>
                            <li><Link to="https://edu.mediaproductionart.com/" target='_blank'>Education</Link></li>
                            <li><Link to="/contact-us">Contact</Link></li>
                        </ul>
                        
                    </div>

                    <div className="col-lg-3 col-md-6 px-4 mt-md-0 mt-4" >
                        <h4 className="fw-bold my-4 text-white">
                            Our Portfolio
                        </h4>
                        <ul className='ul-v'>
                            <li><Link to="/web-development">Web Development </Link></li>
                            <li><Link to="/graphic-design">Graphic Design </Link></li>
                            <li><Link to="/packaging-design">Packaging Design </Link></li>
                            <li><Link to="/logo-design">Logo Design </Link></li>
                            
                        </ul>
                        <div className='text-start'>
                               
                                <div className="">
                                <div className="d-flex mb-0 pb-0">
                                  <div className='my-auto'>
                                  <img src="static/images/icons_email.svg"  alt="" loading="lazy" className='img-fluid' width="15px"></img>
                                  </div>
                                  <div className='my-atuo'>

                                  <img src="static/images/flag1.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </div>
                                  </div>
                                  <p>
                                  <a href="mailto:media.production.art@gmail.com" target='_blank' rel="noreferrer" className='text-white'>media.production.art@ gmail.com</a>
                                  </p>
                                  
                                  <div className="mt-0 pt-0 d-flex">
                                  <div className='my-auto'>
                                  <img src="static/images/icons_email.svg"  alt="" loading="lazy" className='img-fluid' width="15px"></img>
                                  </div>
                                  <div className='my-auto'>

                                  <img src="static/images/flags2.webp"  alt="" loading="lazy" className='mx-1 img-fluid' width="30px"></img>
                                  </div>
                                  </div>
                                  <p>
                                    <a href="mailto:design@tajanna.com" className='text-white'>design@tajanna.com</a>
                                  </p>
                                </div>

                            </div>
                        <div>
                          <img src="static/images/tajanna.png"  alt="" loading="lazy" className="img-fluid" width="100px"></img>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 text-md-start text-center" >
                        
                        <h4 className="fw-bold my-4 text-white">
                            Get In Touch
                        </h4>
                        <form action=""  className="" ref={form}   onSubmit={sendEmail}>

                        <div className='input '>
                            <input type="text" placeholder='Name*' required name="from_name"  className='foot-form'/>
                        </div>
                        <div className='input mt-4'>
                            <input type="email" required placeholder='Email Address*' name="from_email" className='foot-form'/>
                        </div>
                        <div className='input mt-4'>
                            <input type="text" required placeholder='Message*' className='foot-form' name="message" />
                        </div>
                        <div className="my-3">
                        <ReCAPTCHA
                              sitekey="6LdJcMApAAAAAEU7BVWoqoVRUKHWoScN9r_wF8yc"
                              onChange={handleRecaptchaChange}
                              className='recaptcha'
                            />
                        </div>
                       
                          <div className='news-btn-div row text-center mx-1'>
                              <button className='news-btn col-10 text-blue bg-transparent' disabled={!isVerified} type="submit">Send Message</button>
                          </div>
                        </form>
                        <div className='icons mt-4'>
                                <a href="https://www.linkedin.com/in/mediaproductionart/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin "></i></a>
                                <a href="https://www.youtube.com/@mediaproductionarteducation" target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube "></i></a>
                                <a href="https://www.instagram.com/mediaproductionart/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram "></i></a>
                                <a href="https://www.facebook.com/mediaproductionart" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                                <a href="https://www.tiktok.com/@mediaproductionart" target="_blank" rel="noreferrer"><i className="fa-brands fa-tiktok" ></i></a>
                            </div>
                    </div>

                    
                   

                  

                <div className="row pt-3 mt-2 text-white" style={{borderTop:'1px solid #fff'}}>
                    <div className="text-center">
                        <p>© 2011-2024 Media Production Art ALL RIGHTS RESERVED <span className=''>Privacy Policy | Terms of Use</span></p>
                    </div>
                </div>
                </div>

            </div>
      </footer>
    </>
  )
}
