// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { EffectCoverflow, Autoplay, Pagination }from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
      
        slidesPerView={3}
       grabCursor={true}
        spaceBetween={40}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Autoplay, Pagination]}
        className="mySwiper"
        breakpoints={{
          // when window width is <= 768px
          1440: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          // when window width is <= 576px
          576: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }}
      >
        <SwiperSlide>
                <div className="card-slide py-4 px-3 text-sm-start text-center">
                <img src="static/images/one.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Technology</h4>
                        <p> Innovate and stay ahead of the curve with cutting-edge digital solutions for your tech-focused business.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/2.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Manufacturing</h4>
                        <p>Streamline operations and improve efficiency with customized software solutions and digital automation.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/3.webp" alt=""   loading="lazy"className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Food & Beverage</h4>
                        <p>Enhance customer experiences and boost sales with engaging digital marketing strategies and e-commerce platforms.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/4.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Entertainment:</h4>
                        <p>Delight audiences and increase engagement with immersive digital experiences and targeted marketing campaigns.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/5.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Travel & Tourism</h4>
                        <p>Attract travelers and increase bookings with visually stunning websites and strategic digital marketing initiatives.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/6.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Construction</h4>
                        <p>Manage projects more effectively and showcase your work with sophisticated digital solutions and marketing campaigns.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/7.webp" alt=""  loading="lazy" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Legal</h4>
                        <p>Increase visibility and attract clients with professional branding, website design, and digital marketing tailored for law firms.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/retail.webp"  loading="lazy" alt="" className='img-fluid card-image' style={{height:'300px'}}></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Retail</h4>
                        <p>Enhance your online presence and drive sales with captivating e-commerce solutions.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/healthcare.webp" alt=""  loading="lazy" className='img-fluid card-image' ></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Healthcare</h4>
                        <p>Optimize patient care and streamline operations with cutting-edge digital solutions.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/finance.webp" alt=""  loading="lazy" className='img-fluid card-image' ></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Finance</h4>
                        <p>Ensure security and compliance while delivering seamless financial services to your clients.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/hospitality.webp" alt=""  loading="lazy" className='img-fluid card-image' ></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Hospitality</h4>
                        <p>Elevate guest experiences and increase bookings with personalized digital strategies.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                <img src="static/images/education.webp" alt=""  loading="lazy" className='img-fluid card-image' ></img>
                    <div className='card-content text-sm-start text-center'>
                        <h4>Education</h4>
                        <p>Empower learners and educators with innovative technology solutions for the classroom.</p>
                    </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
                <div className="card-slide text-sm-start text-center py-4 px-3">
                        
                    <img src="static/images/estate.webp" alt=""  loading="lazy" className='img-fluid card-image' ></img>
                    
                 
                    <div className='card-content text-sm-start text-center'>
                        <h4>Real Estate</h4>
                        <p> Showcase properties and attract buyers with visually stunning digital marketing campaigns. </p>
                       
                    </div>
                </div>
        </SwiperSlide>
        
      </Swiper>
    </>
  );
}





