import React from 'react'
import Portfolio from './Portfolio'



export default function Logopf(props) {
  return (
    <>
        <section className="padding logo-banner" style={{background:`linear-gradient(rgba(2, 108, 154, 0.8), rgba(2, 108, 154, 0.5)), url(${props.url})`}}>
        <div className="container">
            <h1 className='text-white lined text-center'>{props.title}</h1>
        </div>
        
    </section>

    <section className='padding'>
        <div className="container text-center">
            <h1 className='text-blue lined3 my-5'>Logo Design Portfolio</h1>

            <Portfolio heading="" link1="static/images/portfolio/logo/1.webp" image1="static/images/portfolio/logo/1.webp" name1="MPA Travel Logo" link2="static/images/portfolio/logo/2.webp" image2="static/images/portfolio/logo/2.webp" link3="static/images/portfolio/logo/3.webp" name2="Herbie's Logo" image3="static/images/portfolio/logo/3.webp" name3="EndoSurge Logo"/>

            <Portfolio heading="" link1="static/images/portfolio/logo/4.webp" image1="static/images/portfolio/logo/4.webp" name1="Kane Foundation Logo" link2="static/images/portfolio/logo/5.webp" image2="static/images/portfolio/logo/5.webp" link3="static/images/portfolio/logo/6.webp" name2="Black Buddha Logo" image3="static/images/portfolio/logo/6.webp" name3="Kuddles Nursery Logo"/>

            <Portfolio heading="" link1="static/images/portfolio/logo/7.webp" image1="static/images/portfolio/logo/7.webp" name1="Juice Collection Logo" link2="static/images/portfolio/logo/8.webp" image2="static/images/portfolio/logo/8.webp" link3="static/images/portfolio/logo/9.webp" name2="Acres 4 Change Logo" image3="static/images/portfolio/logo/9.webp" name3="Vet4bud Logo"/>
                <div className='mt-5'>
                    <a href="https://portfolio.mediaproductionart.com/img/Media%20Portfoilo_Design%202023_Final%20With%20Website.pdf" rel='noreferrer' target='_blank' className='btn btn-1'>See More <i className="fa-solid fa-arrow-right"></i></a>        
                </div>
        </div>
    </section>
    </>
  )
}
