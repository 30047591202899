import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination }from 'swiper/modules';




export default function About(props) {
  const [showVideoOverlay, setShowVideoOverlay] = useState(false);

  const openVideoOverlay = () => {
    setShowVideoOverlay(true);
    document.body.style.overflowY = 'hidden'; // Prevent scrolling when overlay is open
  };

  const closeVideoOverlay = () => {
    setShowVideoOverlay(false);
    document.body.style.overflowY = 'auto'; // Allow scrolling when overlay is closed
  };
  return (
    <>
      <div className="about-banner">
          <div className="container">
            <h1 className='text-white text-center'>{props.title}</h1>
           
          </div>
      </div>

      <section className="padding">
        <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto text-md-start text-center">
                <h2 className='fw-bold text-blue lined2'>MPA: Where Digital Dreams Become Reality</h2>
                <p className="mt-3">
                Since our establishment in 2012, we've been dedicated to pushing the boundaries of digital solutions. From captivating web design to seamless web development and strategic marketing, we're committed to empowering businesses to thrive in the digital age. Our journey is defined by a relentless pursuit of excellence, fueled by a passion for delivering results that exceed expectations. At MPA, we don't just meet standards; we set them, providing our clients with unparalleled expertise, creativity, and professionalism. Join us on our mission to redefine success in the digital realm.
                </p>
              </div>

             

              <div className="col-lg-6 mt-lg-0 mt-4">
            
                  <div className="video-img" onClick={openVideoOverlay}>
                    <img src="static/images/icons-05.svg" alt="" loading='lazy'  className='img-fluid video-icon' ></img>
                </div>
              </div>

            </div>
        </div>


      </section>
                {showVideoOverlay &&      
                    <div className="video-overlay">
                        <div className="container text-center">
                          <div>
                            <p className='text-end fs-3 text-white closed' id="pauseButton" onClick={closeVideoOverlay}><i className="fa-solid fa-xmark"></i></p>
                          </div>
                          <div id='video-iframe' className='mt-4 pt-4'>
                              <iframe width="460" height="215" className="video" src="https://www.youtube.com/embed/cd7yr66N2ac?si=fXzcHctin28DUuc2" title="YouTube video player" id="myIframe" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                          </div>
                        </div>
                      </div>}

      <section className="padding">
        <div className="container">
          <h1 className="fw-bold text-blue lined2 text-center">Meet Our Team</h1>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/zubair.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Zubair Ahmed</h4>
                  <p className='text-blue fw-bold'>CEO / Founder</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/fareed.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Fareed Soomro</h4>
                  <p className='text-blue fw-bold'>Director</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/TaJanna.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>TaJanna Mallory</h4>
                  <p className='text-blue fw-bold'>Director USA Office</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/dabeer.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Dabeer Siddiquie</h4>
                  <p className='text-blue fw-bold'>Graphic Designer</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/mubeen.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Mubeen Ali</h4>
                  <p className='text-blue fw-bold'>Graphic Designer</p>
                </div>
            </div>
            
            
            
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/ameeque.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Syed Ameeque</h4>
                  <p className='text-blue fw-bold'>Full Stack Developer</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/saad.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Saad Khan</h4>
                  <p className='text-blue fw-bold'>Front End Developer</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/bisma.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Bisma Khan</h4>
                  <p className='text-blue fw-bold'>Graphic Designer</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center mt-3">
                <img src="static/images/about/narmeen.webp" alt=""  loading="lazy" className='img-fluid team'></img>
                <div className='text-center'>
                  <h4 className='fw-bold mt-3'>Narmeen</h4>
                  <p className='text-blue fw-bold'>Front Desk Officer</p>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section className="team-bg padding">
          
      </section>
    


      <section className="padding">
          <div className="container">
            <div className="row">
                <div className="col-lg-6 my-auto px-md-4 text-md-start text-center">
                <h2 className='fw-bold text-blue lined2'>Why Choose Us</h2>
                <p className="mt-3">
                Choose MPA for unparalleled expertise and dedication to excellence. With a proven track record since 2012, we stand at the forefront of digital innovation, delivering tailored solutions that drive real results. 

                </p>

                <p>
                Our team of seasoned professionals is committed to understanding your unique needs and surpassing your expectations, ensuring every project is executed with precision and professionalism.
                </p>
                    <div>
                        <Link to="/contact-us" className="btn btn-1 mt-md-0 mt-4">Contact Us</Link>
                    </div>
                </div>

                <div className="col-lg-6 mt-lg-0 mt-3">
                  <div className="row">
                    <div className="col-md-6">
                        <div className="about-card text-center">
                            <img src="static/images/about/icons-02.webp" alt=""  loading="lazy" className="img-fluid" width="100px"></img>
                            <h5 className='mt-3'>Proven Results</h5>
                        </div>
                    </div>

                    <div className="col-md-6 mt-md-0 mt-3">
                        <div className="about-card text-center">
                            <img src="static/images/about/icons-03.webp" alt=""  loading="lazy" className="img-fluid" width="100px"></img>
                            <h5 className='mt-3'>100% Quality Work</h5>
                        </div>
                    </div>

                    <div className="col-md-6 mt-3">
                        <div className="about-card text-center">
                            <img src="static/images/about/icons-04.webp" alt=""  loading="lazy" className="img-fluid" width="100px"></img>
                            <h5 className='mt-3'>Tailored Solutions</h5>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="about-card text-center">
                            <img src="static/images/about/icons-05.webp" alt=""  loading="lazy" className="img-fluid" width="100px"></img>
                            <h5 className='mt-3'>Client-Centric Approach</h5>
                        </div>
                    </div>

                  </div>
                </div>

            </div>
          </div>
      </section>

      <section className="padding bg-light">
          <div className="container">
            <h1 className="fw-bold lined2 text-center">Meet a Few of Our Customers</h1>
                  <Swiper
                    slidesPerView={5}
                    spaceBetween={20}
                    // centeredSlides={true}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper py-5 my-5"
                    breakpoints={{
                      // when window width is <= 768px
                      1200:{
                        slidesPerView: 5,
                        
                      },
                      992:{
                        slidesPerView: 4,
                        
                      },
                      768: {
                        slidesPerView: 3,
                        
                      },
                      // when window width is <= 576px
                      576: {
                        slidesPerView: 2,
                     
                      },
                      320: {
                        slidesPerView: 1,
                     
                      }
                    }}
                  >
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center ">
                        <img src="static/images/services/logo1.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo2.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center ">
                        <img src="static/images/services/logo3.webp"  alt="" loading="lazy" className='img-fluid '></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo4.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo5.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo6.webp"  alt="" loading="lazy" className='img-fluid '></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo7.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo8.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo9.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo10.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo11.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo12.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo13.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo14.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo15.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo16.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-slide text-center d-flex align-items-center justify-content-center">
                        <img src="static/images/services/logo17.webp"  alt="" loading="lazy" className='img-fluid'></img>
                      </div>
                    </SwiperSlide>

                 
                
              </Swiper>
          </div>
        </section>


    </>
  )
}
